<template>
  <div class="brand-container">
    <div class="header_father">
      <div class="header1 list_title">
        <van-nav-bar title="品牌库" left-text="返回" right-text="创建品牌" left-arrow @click-right="onClickRights"  @click-left="onClickLefts"></van-nav-bar>
        <div style="margin-top: 50px;">
        </div>
            <van-tabs v-model="active">
              <van-tab title="审核通过"></van-tab>
              <van-tab title="待审通过"></van-tab>
            </van-tabs>  
      </div>
      <!-- 审核通过 -->
      <div v-if="active == 0">
        <div class="texts_cs" v-if="flags"></div>
      </div>
      <!-- 待审通过 -->
      <div v-else>
        <div class="texts_cs" v-if="flags1"></div>
      </div>
      <van-loading size="24px" v-if="lodings" style="padding-top: 120px">加载中...</van-loading>
    </div>
      <div class="father_mr1" v-if="active == 0">
        <van-empty image="search" v-if="showEmpty" description="哎呀! 未找到相关记录..." />
          <div class="product_cs">
          </div>
          <div class="animated fadeInUp2" v-if="passBrand.length>0">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
              <div class="commodityList">
                <div class="commodity_cs">
                  <van-cell is-link v-for="(item,index) in passBrand" :key='index' @click="commodityDetails(item)" class="list_cell">
                    <van-card :title="item.name" class="title_img">
                      <template #thumb>
                        <van-image lazy-load v-if="item.powerAttorneys" :src="item.powerAttorneys[0].url" />
                        <van-image lazy-load v-else :src="require('../../assets/img/img.png')" />
                      </template>
                      <template #tags>
                        <p class="commodity"><span>商标分类: </span>{{item.brandType||'-'}}</p>
                        <p class="commodity"><span>商标注册证号: </span>{{item.brandNo||'-'}}</p>
                        <p class="commodity"><span>授权方式: </span>{{item.licenseScope||'-'}}</p>
                      </template>
                    </van-card>
                    <template>
                      <div style="width: 100%; background-color: #fafafa;">
                        <div class="border_cs"></div>
                      </div>
                    </template>
                  </van-cell>
                </div>
              </div>
            </van-list>
          </div>
        </div>
        <div class="father_mr1" v-else>
          <van-empty image="search" v-if="showEmpty1" description="哎呀! 未找到相关记录..." />
          <div class="product_cs">
          </div>
          <div class="animated fadeInUp2" v-if="pendingBrand.length>0">
            <van-list v-model="loading" :finished="finished1" finished-text="没有更多了" @load="onLoad1">
              <div class="commodityList">
                <div class="commodity_cs">
                  <van-cell is-link v-for="(item,index) in pendingBrand" :key='index' @click="commodityDetails1(item)" class="list_cell">
                    <van-card :title="item.name" class="title_img">
                      <template #thumb>
                        <van-image lazy-load v-if="item.powerAttorneys" :src="item.powerAttorneys[0].url" />
                        <van-image lazy-load v-else :src="require('../../assets/img/img.png')" />
                      </template>
                      <template #tags>
                        <p class="commodity"><span>商标分类: </span>{{item.brandType||'-'}}</p>
                        <p class="commodity"><span>商标注册证号: </span>{{item.brandNo||'-'}}</p>
                        <p class="commodity"><span>审核状态: </span><span style="color: red;" v-if="item.statusName.indexOf('审核未通过') != -1">{{item.statusName||'-'}}</span><span v-else>{{item.statusName||'-'}}</span></p>
                      </template>
                    </van-card>
                    <template>
                      <div style="width: 100%; background-color: #fafafa;">
                        <div class="border_cs"></div>
                      </div>
                    </template>
                  </van-cell>
                </div>
              </div>
            </van-list>
          </div>
        </div>
    <div class="title_serch">
    </div>
    <van-tabbar route>
      <van-tabbar-item to="/productAudit" icon="wap-nav">产品提报库</van-tabbar-item>
      <van-tabbar-item to="/brand" icon="card">品牌库</van-tabbar-item>
      <van-tabbar-item to="/product" icon="gift-card">产品库</van-tabbar-item>
      <van-tabbar-item to="/mine" icon="manager">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { Notify } from 'vant'
export default {
  data() {
    return {
      active: 0,
      loading: false,
      flags: true,
      flags1: true,
      showEmpty: false,
      showEmpty1: false,
      judegs: true,
      finished: false,
      finished1: false,
      lodings: false,
      // 审核通过品牌
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 待审通过品牌
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      passBrand: [],//审核通过品牌列表
      pendingBrand: [],//待审核通过品牌列表
    }
  },
  created() {
    if(this.$route.query.active != undefined ){
       this.active = Number(this.$route.query.active)
    }
    this.flags = false,
    this.flags1 = false,
    this.lodings = true,
    setTimeout(()=> {
        this.lodings = false,
        this.getPassBrand();
        this.getpendingBrand();
    }, 300)
  },
  methods: {
    onClickRights(){
        this.$router.push({
          path: '/addBrand',
          query: {}
        })
    },
    // 审核通过品牌
    getPassBrand(){
      this.$http.ajax('/ffkj-main/merchantBrand/page', {
          pageEnum: 'QUERY_PAGE',
          merchantId: localStorage.getItem('merchantId'),
          pageNum: this.query.pageNum,
          pageSize: this.query.pageSize
      }, 'get', {}).then(res => {
          if (res.code == 0) {
            this.finished = false;
            let row = res.data.list;
            this.passBrand = this.passBrand.concat(row);
            this.flags = true;
            this.query.total = res.data.total;
            if(res.data.total > 0 ){
              this.flags = true;
              this.showEmpty = false;
            }else{
              this.showEmpty = true;
              this.flags = false
            }
            this.judegs = true;
            this.loading = false;
            if (this.passBrand.length == res.data.total) {
             // 加载结束
             this.finished = true
             return
            } else {
              this.finished = false
            }
          }
          else {
            Notify({ type: 'danger', message: res.message });
          }
          var timer = setTimeout(function () {
              this.loading = false
              window.clearTimeout(timer)
          }.bind(this), 300)
      })
    },
    // 待审通过品牌
    getpendingBrand(){
      this.$http
      .ajax('/ffkj-main/merchantBrand/page'
      ,{
          pageEnum: 'EDIT_PAGE',
          merchantId: localStorage.getItem('merchantId'),
          pageNum: this.query1.pageNum,
          pageSize: this.query1.pageSize
      },'get',{}).then(res=>{
          if( res.code == 0 ){
              this.flags1 = true;
              this.finished1 = false;
              let rows = res.data.list;
              this.pendingBrand = this.pendingBrand.concat(rows);
              this.query1.total = res.data.total;
              if(res.data.total > 0){
                this.showEmpty1 = false;
                this.flags = true;
              }else{
                this.flags1 = false;
                this.showEmpty1 = true;
              }
              if(this.pendingBrand.length >= res.data.total){
                this.finished1 = true;
              }else{
                this.finished1 = false;
              }
          }
          else{
              Notify({ type: 'danger', message: res.message });
          }
          var timer = setTimeout(function(){
              this.loading = false
              window.clearTimeout(timer)
          }.bind(this), 300)
      })
    },
    onClickLefts() {
      window.history.go(-1);
    },
    newProductDetails() {
      this.$router.push({
        path: '/productDetails',
        query: {}
      })
    },
    // 审核通过品牌详情
    commodityDetails(item) {
      this.$router.push({
        path: '/brandDetalis',
        query: {
          id: item.id
        }
      })
    },
    // 待审核品牌详情
    commodityDetails1(item) {
      this.$router.push({
        path: '/brandDetalis1',
        query: {
          id: item.id
        }
      })
    },
    //滚动加载时触发，list组件定义的方法
    onLoad() {
      setTimeout(
        function () {
          this.query.pageNum++
          this.getPassBrand();
        }.bind(this),
        3000
      )
    },
    //滚动加载时触发，list组件定义的方法
    onLoad1() {
      setTimeout(
        function () {
          this.query1.pageNum++
          this.getpendingBrand();
        }.bind(this),
        3000
      )
    }
  },
}
</script>
<style>
</style>